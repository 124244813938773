import { ref, onMounted, watchEffect } from 'vue';

export function setMeta(title: string, description: string) {
    document.title = `${title}${(title.length ? ' - ' : '')}NG Hantverk - Upplev äventyret med våra handgjorda och exklusiva knivar och fodral`;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute('content', description);
    } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.setAttribute('name', 'description');
        newMetaDescription.setAttribute('content', description);
        document.head.appendChild(newMetaDescription);
    }
}

export function useMeta(title: string, description: string) {
    const titleRef = ref(title);
    const descriptionRef = ref(description);

    onMounted(() => {
        setMeta(titleRef.value, descriptionRef.value);
    });

    watchEffect(() => {
        setMeta(titleRef.value, descriptionRef.value);
    });

    return {
        title: titleRef,
        description: descriptionRef,
    };
}