import { ref, onMounted, watchEffect } from 'vue';

export function setCanonical(href: string) {
    const address = 'https://www.nghantverk.se' + (href.length > 0 ? `/${href}` : href);
    const canonical = document.querySelector('link[rel="canonical"]');

    if (canonical) {
        canonical.setAttribute('href', address);
    } else {
        const metaCanonicalValue = document.createElement('link');
        metaCanonicalValue.setAttribute('rel', 'canonical');
        metaCanonicalValue.setAttribute('href', address);
        document.head.appendChild(metaCanonicalValue);
    }
}

export function removeCanonical() {
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical)
        canonical.remove();
}

export function useCanonical(href: string) {
    const hrefRef = ref(href);

    onMounted(() => {
        setCanonical(hrefRef.value);
    });

    watchEffect(() => {
        setCanonical(hrefRef.value);
    });

    return {
        href: hrefRef
    };
}

export function dontUseCanonical() {
    removeCanonical();
}